import React from "react"
import { Link, graphql } from "gatsby"

import Layout, { zeusTheme } from "../../components/layout"
import styles from "./entry.module.scss"

import widont from "widont"
import ZeusModules from "../../components/zeusModules"

export const query = graphql`
  query {
    craft {
      page: entry(section: "expertiseIndex") {
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
          ... on Craft_expertiseIndex_expertiseIndex_Entry {
              hideTitle
              zeusModules {
                  ...ZeusModules
              }
          }
      }
      liveScoping: entry(section: "liveScoping") {
        uri
      }
      contact: entry(section: "contact") {
        uri
      }
    }
  }
`

const ContactPage = ({ data }) => {
  const seo = data.craft.page.seomatic
  const page = data.craft.page;

  return (
    <Layout seo={seo} theme={zeusTheme}>

      <div className={`page-content-module ${styles.pageContent}`}>
        <div className="wrapper">
          <div
            className={`${styles.title} ${
              page.hideTitle ? "screen-reader-only" : ""
            }`}
          >
            <div className="columns is-desktop">
              <div className="column is-7-desktop is-offset-1-desktop">
                <h1 className="h-1 mb-2">{widont(page.title)}</h1>
              </div>
            </div>
          </div>
        </div>

        {page.zeusModules && <ZeusModules data={page.zeusModules} />}
      </div>
    </Layout>
  )
}

export default ContactPage
